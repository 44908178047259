import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import BrowserRouter, Routes, and Route
import './App.css';
import Homepage from './Homepage'; // Import Homepage component
import logo from './logo.svg';

function App() {
  return (
    <Router> 
      <Routes>
        <Route path="/" element={<Homepage />} />
      </Routes>
    </Router> 
  );
}

export default App;
