import React, { useState, useEffect, useRef  } from "react";
import "./Homepage.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import emailjs from 'emailjs-com';

export const Homepage = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [animateWassup, setAnimateWassup] = useState(false);
  const [animateText, setAnimateText] = useState(false);
  const [fadeInSoftware, setFadeInSoftware] = useState(false);
  const [animateEllipse, setAnimateEllipse] = useState(false);
  const [animateIntro, setAnimateIntro] = useState(false);
  const [isVisibleCard1, setVisibleCard1] = useState(false);
  const [isVisibleCard2, setVisibleCard2] = useState(false);
  const [isVisibleCard3, setVisibleCard3] = useState(false);
  const [isVisibleCard4, setVisibleCard4] = useState(false);
  const [isVisibleCard5, setVisibleCard5] = useState(false);
  const [isVisibleCard6, setVisibleCard6] = useState(false);
  const [isVisibleCard7, setVisibleCard7] = useState(false);
  const [startTyping, setStartTyping] = useState(false); 
  const [isCareerVisible, setIsCareerVisible] = useState(false);
  const [timelineHeight, setTimelineHeight] = useState("0px");
  const [fadeInAvatar, setFadeInAvatar] = useState(false);
  const [animateContactTitle, setAnimateContactTitle] = useState(false);
  const [projectsCount, setProjectsCount] = useState(0);
  const [workingCount, setWorkingCount] = useState(0);
  const [projectReached, setProjectReached] = useState(false)

  const contactRef = useRef(null);
  const mlprojectRef = useRef(null);

  const careerRef = useRef(null);


  const introRef = useRef(null);
  const skillsRef = useRef(null);
  const projectRef = useRef(null);

  emailjs.init("v0lBeDsEMxwbrqXdK");
  const handleSubmit = (e) => {
    e.preventDefault();
  
    emailjs.sendForm('service_kyc26fv', 'template_j22ha3m', e.target)
      .then((result) => {
        console.log(result.text);
        alert('Email sent successfully!');
      }, (error) => {
        console.log(error.text);
        alert('Email to send the message, please try again.');
      });
  
    e.target.reset();
  };
  

  useEffect(() => {
    const contactObserver = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setAnimateContactTitle(true);
        }
      },
      { threshold: 0.5 } // Adjust as needed
    );
  
    if (contactRef.current) {
      contactObserver.observe(contactRef.current);
    }
  
    return () => {
      if (contactRef.current) {
        contactObserver.unobserve(contactRef.current);
      }
    };
  }, []);
  

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsCareerVisible(true);
        }
      },
      { threshold: 0.6 } // Adjust threshold as needed
    );
  
    if (careerRef.current) {
      observer.observe(careerRef.current);
    }
  
    return () => {
      if (careerRef.current) {
        observer.unobserve(careerRef.current);
      }
    };
  }, []);

  useEffect(()=>{
    const projectObserver = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setProjectReached(true);
        }
      },
      { threshold: 0.4 } // Reduced threshold for better detection
    );
    
    if (projectRef.current) {
      projectObserver.observe(projectRef.current);
    }
    
    return () => {
      if (projectRef.current) {
        projectObserver.unobserve(projectRef.current);
      }
    };
  },[]);

  useEffect(() => {
    if (isCareerVisible && careerRef.current) {
      setTimeout(() => {
        const timeline = careerRef.current.querySelector('.timeline');
        timeline.classList.add('expand');
      }, 300);
      
      const timelineItems = careerRef.current.querySelectorAll('.timeline-item');
      console.log(timelineItems);
      timelineItems.forEach((item, index) => {
        setTimeout(() => {
          item.style.opacity = 1;
        }, index * 300); // Delay each item by 300ms
      });
    }
  }, [isCareerVisible]);


  useEffect(() => {
    window.onload = () => {
      setIsLoaded(true); 
      setTimeout(() => setFadeInAvatar(true), 0);
      setTimeout(() => setAnimateWassup(true), 500);
      setTimeout(() => setAnimateText(true), 1000);
      setTimeout(() => setFadeInSoftware(true), 2500);
      setTimeout(()=>{startProjectsCount();startWorkingCount()},2800)

      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            console.log("Hahhai");
            setAnimateIntro(true);
            setStartTyping(true);
          }
        },
        { threshold: 0.9 }
      );
  
      if (introRef.current) {
        observer.observe(introRef.current);
      }
  
      return () => {
        if (introRef.current) {
          observer.unobserve(introRef.current);
        }
      };
    };

    const skillsObserver = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          console.log("Skills section is now visible");
      
          setTimeout(() => setVisibleCard1(true), 300);
          setTimeout(() => setVisibleCard2(true), 600);
          setTimeout(() => setVisibleCard3(true), 900);
          setTimeout(() => setVisibleCard4(true), 1200);
          setTimeout(() => setVisibleCard5(true), 1500);
          setTimeout(() => setVisibleCard6(true), 3000);
          setTimeout(() => setVisibleCard7(true), 3500);
        }
      },
      { threshold: 0.4 } // Reduced threshold for better detection
    );
    
    if (skillsRef.current) {
      skillsObserver.observe(skillsRef.current);
    }
    
    return () => {
      if (skillsRef.current) {
        skillsObserver.unobserve(skillsRef.current);
      }
    };

  }, []);


  const startProjectsCount = () => {
    let count = 0;
    const interval = setInterval(() => {
      count++;
      setProjectsCount(count);
      if (count === 6) { 
        clearInterval(interval);
      }
    }, 100); 
  };
  
  const startWorkingCount = () => {
    let count = 0;
    const interval = setInterval(() => {
      count++;
      setWorkingCount(count);
      if (count === 1) { 
        clearInterval(interval);
      }
    }, 100); 
  };
  
  
  

  return (
    <div className="homepage d-flex flex-column justify-content-center" style={{ display: isLoaded ? 'block' : 'none' }}>
      
      <div className="content-area position-relative">
        {/*<div className="overlay position-absolute"></div>*/}
        <div className="stat-box position-absolute flex-column">
          <div className="stats d-flex flex-column">
            <p className="text-white my-num" >{projectsCount}</p>
            <p className="text-dark my-label">Projects Completed</p>
          </div>
          <div className="stats">
          <p className="text-white my-num">{workingCount}</p>
            <p className="text-dark my-label">Working Experiences</p>
          </div>
        </div>
        <div className=" position-absolute center-shape"></div>
        <img className={`position-absolute show-image ${fadeInAvatar ? 'fade-in' : ''} `} src="./images/avatarnobg.png"/>
        <p className={` position-absolute i-am-a-software ${fadeInSoftware ? 'fade-in' : ''}`}> I'm a software developer with experience in various programming languages and a passion for the machine learning landscape.</p>
        <div className="text-content position-absolute d-flex">
        <img className={`avatar-image mr-5 ${fadeInAvatar ? 'fade-in' : ''}`} src={require('./images/my-avatar.png')}/>
        <div className="ml-5">
        <div className={`wassup-im ${animateWassup ? 'slide-in' : ''}`}>Wassup, I'M</div>
        <div className={`text-wrapper ${animateText ? 'typing' : ''}`}>YEONG LIM</div>
        </div>

        
        </div>
      </div>

      <div className={`section introduction ${animateIntro ? 'slide-up-fade-in' : ''}`} ref={introRef}>
        <div className="text-wrapper-section">INTRODUCTION</div>
        <p className={`i-am-a-passionate ${animateIntro ? 'typing-multi-line' : ''}`}>
        I am a passionate software developer proficient in languages like C#, Java, Kotlin, and Python, actively seeking opportunities to contribute to and innovate within the software development landscape. I am also actively pursuing opportunities in the field of machine learning to further develop my skills and contribute to innovative projects.
        </p>
      </div>


      <div className="section what-can-i-do">
        <div className="text-wrapper-section">WHAT CAN I DO?</div>

        <div className="d-flex py-5 skill-card-cont">
          <div className="skill-card">
            <img className="skill-icon" alt="Mingcute code fill" src={require('./images/mingcute-code-fill.svg').default} />
            <div className="skill-text mt-4">FRONTEND</div>
          </div>
          
          <div className="skill-card">
            <img className="skill-icon" alt="Icon park outline" src={require('./images/icon-park-outline-database-code.svg').default} />
            <div className="skill-text mt-4">BACKEND</div>
          </div>

          <div className="skill-card">
            <img className="skill-icon" alt="Bi stack" src={require('./images/machine-learning.svg').default} />
            <div className="skill-text mt-4">MACHINE LEARNING</div>
          </div>
        </div>
      </div>


      <div className="section my-skills" ref={skillsRef}>
        <div className="text-wrapper-section">MY SKILLS</div>
        <div className="my-skills-container d-flex justify-content-left align-items-center flex-wrap mt-3">
          <div className={`my-skills-card ${isVisibleCard1 ? 'slide-in-right' : ''}`}>
            <div className="my-skills-icon-wrapper">
              <img className="my-skills-icon" alt="Python" src={require('./images/akar-icons-python-fill.svg').default} />
            </div>
          </div>
          <div className={`my-skills-card ${isVisibleCard2 ? 'slide-in-right' : ''}`}>
            <div className="my-skills-icon-wrapper">
              <img className="my-skills-icon" alt="Node.js" src={require('./images/teenyicons-nodejs-solid.svg').default} />
            </div>
          </div>
          <div className={`my-skills-card ${isVisibleCard3 ? 'slide-in-right' : ''}`}>
            <div className="my-skills-icon-wrapper">
              <img className="my-skills-icon" alt="JavaScript" src={require('./images/teenyicons-javascript-solid.svg').default} />
            </div>
          </div>
          <div className={`my-skills-card ${isVisibleCard4 ? 'slide-in-right' : ''}`}>
            <div className="my-skills-icon-wrapper">
              <img className="my-skills-icon" alt="Vector Graphics" src={require('./images/kotlin.svg').default} />
            </div>
          </div>
          <div className={`my-skills-card ${isVisibleCard5 ? 'slide-in-right' : ''}`}>
            <div className="my-skills-icon-wrapper">
              <img className="my-skills-icon" alt="Firebase" src={require('./images/logos-firebase.svg').default} />
            </div>
          </div>
          <div className={`my-skills-card ${isVisibleCard6 ? 'slide-in-right' : ''}`}>
            <div className="my-skills-icon-wrapper">
              <img className="my-skills-icon" alt="CSharp" src={require('./images/csharp.svg').default} />
            </div>
          </div>
          <div className={`my-skills-card ${isVisibleCard7 ? 'slide-in-right' : ''}`}>
            <div className="my-skills-icon-wrapper">
              <img className="my-skills-icon" alt="Pytorch" src={require('./images/pytorch.svg').default} />
            </div>
          </div>
        </div>
      </div> 

      <div className="section my-projects" ref={mlprojectRef}>
        <div className="text-wrapper-section">ML PROJECTS</div>
        <div className="ml-project-container">
          <div className="ml-project d-flex align-items-center">
            <div className="icon"><a target="_blank" href="https://github.com/tyl-99/football-player-value-prediction"><img src="./images/github.png" /></a></div>
            <div className="ml-name"><a target="_blank" href="https://github.com/tyl-99/football-player-value-prediction"><p data-replace="FFN Football Player Value Prediction">FFN Football Player Value Prediction</p></a></div>
          </div>
          <div className="ml-project d-flex align-items-center">
            <div className="icon"><a target="_blank" href="https://github.com/tyl-99/mnist-classification"><img src="./images/github.png" /></a></div>
            <div className="ml-name"><a target="_blank" href="https://github.com/tyl-99/mnist-classification"><p data-replace="MNIST dataset Classifier">MNIST dataset Classifier</p></a></div>
          </div>
          <div className="ml-project d-flex align-items-center">
            <div className="icon"><a target="_blank" href="https://github.com/tyl-99/gym-equipment-detector"><img src="./images/github.png" /></a></div>
            <div className="ml-name"><a target="_blank" href="https://github.com/tyl-99/gym-equipment-detector"><p data-replace="GYM Equipment Detection with Pytorch ResNet">GYM Equipment Detection with Pytorch ResNet </p></a></div>
          </div>
          <div className="ml-project d-flex align-items-center">
            <div className="icon"><a target="_blank" href="https://github.com/tyl-99/football-basketball-detector"><img src="./images/github.png" /></a></div>
            <div className="ml-name"><a target="_blank" href="https://github.com/tyl-99/football-basketball-detector"><p data-replace="Football & Basketball Classifier with yolov5">Football & Basketball Classifier with yolov5</p></a></div>
          </div>
          <div className="ml-project d-flex align-items-center">
            <div className="icon"><a target="_blank" href="https://github.com/tyl-99/player-detector"><img src="./images/github.png" /></a></div>
            <div className="ml-name"><a target="_blank" href="https://github.com/tyl-99/player-detector"><p data-replace="Football Player Tracker with yolov5">Football Player Tracker with yolov5</p></a></div>
          </div>
        </div>

      </div>

      <div className="section my-projects" ref={projectRef}>
        <div className="text-wrapper-section">OTHER PROJECTS</div>
        <div className="row col-md-12 mt-4">
        <div class="container">
          <div class="project-container">
              <div id="box1" class="grid-box">
                  <img src="./images/dlaid 1.png" className={`my-img hori ${projectReached? 'reached': ''}`}/>
                  <div class="project-content">
                    <h3>FIT99</h3>
                    <div className="content-line"></div>
                    <div className="content-desc">
                      <div class="desc">A MOBILE FITNESS APP DEVELOPED USING KOTLIN</div>
                      <div class="content-icon">
                        <div className="icon">
                          <a target="_blank" href="https://github.com/tyl-99/FIT99APP"><img src="./images/github.png" /></a>
                        </div>
                        <div className="icon">
                          <img src="./images/internet.png" />
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div id="box2" class="grid-box">
                <img src="./images/xilnex 1.png" className={`my-img hori ${projectReached? 'reached': ''}`}/>
                <div class="project-content">
                    <h3>Xilnex Products</h3>
                    <div className="content-line"></div>
                    <div className="content-desc">
                      <div class="desc">Xilnex OTM & Xilnex Web Report</div>
                      <div class="content-icon">
                        <div className="icon">
                          <img src="./images/github.png" />
                        </div>
                        <div className="icon">
                          <a target="_blank" href="https://www.xilnex.com/"><img src="./images/internet.png" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div id="box3" class="grid-box">
                <img src="./images/project-3.png" className={`my-img verti ${projectReached? 'reached': ''}`}/>
                <div class="project-content">
                    <h3>More</h3>
                    <div className="content-line"></div>
                    <div className="content-desc">
                      <div class="desc">Explore More Projects</div>
                      <div class="content-icon">
                        <div className="icon">
                          <img src="./images/internet.png" />
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div id="box4" class="grid-box">
                <img src="./images/project-4.png" className={`my-img hori ${projectReached? 'reached': ''}`}/>
                <div class="project-content">
                    <h3>No project Yet</h3>
                    <div className="content-line"></div>
                    <div className="content-desc">
                      <div class="desc">Pending Project..........</div>
                      <div class="content-icon">
                        <div className="icon">
                          <img src="./images/github.png" />
                        </div>
                        <div className="icon">
                          <img src="./images/internet.png" />
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
        </div>
      </div>


      <div className="section my-career" ref={careerRef} style={{ width: '100%',  height:'max-content', minHeight:'700px'}}>
        <div className="text-wrapper-section">MY CAREER</div>
          <div className="timeline-container d-flex justify-content-center align-items-start w-100 h-100 mt-3" style={{ minHeight:'700px'}}>
          {/*<img src='./images/image-2.png' alt="Career Background" className="career-background-image" />*/}
           <div className={`timeline`} >
            <div className="timeline-start"></div>
            <div className="timeline-item right">
              <div className="timeline-icon" style={{backgroundColor:'white'}}>
                <img alt="Work Experience 1" src={require('./images/material-symbols-work-outline.svg').default} />
              </div>
              <div className="timeline-content">
                <div className="year">2022-2023</div>
                <div className="description">Intern at Qynix Technologies</div>
              </div>
            </div>
            <div className="timeline-item left">
              <div className="timeline-icon" style={{backgroundColor:'white'}}>
                <img alt="Work Experience 2" src={require('./images/material-symbols-work-outline.svg').default} />
              </div>
              <div className="timeline-content">
                <div className="year">2024</div>
                <div className="description">Intern at Xilnex</div>
              </div>
            </div>
            <div className="timeline-item right end">
              <a href="https://firebasestorage.googleapis.com/v0/b/fit99-9dacb.appspot.com/o/Resume%2FTanYeongLim_SoftwareEngineer.pdf?alt=media&token=8647c068-5b82-49dd-b983-59628554fa30" target="_blank" rel="noopener noreferrer" className="timeline-icon resume" style={{backgroundColor:'white'}}>
                <img alt="Resume" src={require('./images/healthicons-i-exam-qualification.svg').default} /></a>
                <div className="timeline-content">
                  <div className="year">Resume</div>
                  <div className="description">View My Resume</div>
                </div>
              
            </div>
          </div>
        </div>
      </div>

      <div className="section contact-section mt-4" ref={contactRef}>
        <h2 className={`section-title ${animateContactTitle ? 'typing2' : ''}`}>Contact Me.</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">Your Name</label>
            <input type="text" className="form-control" id="name" name="name" placeholder="What’s your name"/>
          </div>
          <div className="mb-3">
            <label htmlFor="title" className="form-label">Title</label>
            <input type="text" className="form-control" id="title" name="title" placeholder="Enter Title"/>
          </div>
          <div className="mb-3">
            <label htmlFor="message" className="form-label">Message</label>
            <textarea className="form-control" id="message" rows="3" name="message" placeholder="Enter Your Message"></textarea>
          </div>
          <button type="submit" className="btn btn-primary">
            Send<img src={require('./images/send.svg').default} alt="Send Icon" className="send-icon" /> 
          </button>

        </form>
      </div>


    <footer className="footer-section mt-5">
      <h2 className="footer-name text-center">TAN YEONG LIM</h2>
      <div className="social-media-icons mt-5">
        <a href="https://www.instagram.com/yeong_lim2002/" target="_blank" rel="noopener noreferrer">
          <div className="ellipse-icon" style={{backgroundColor:'white!important'}}>
            <img src={require('./images/skill-icons-instagram.svg').default} alt="Instagram" />
          </div>
        </a>
        <a href="https://www.linkedin.com/in/yeong-lim-tan-18463b151/" target="_blank" rel="noopener noreferrer">
          <div className="ellipse-icon" style={{backgroundColor:'white!important'}}>
            <img src={require('./images/ant-design-linkedin-filled.svg').default} alt="LinkedIn" />
          </div>
        </a>
        <a href="https://www.facebook.com/tanyeonglim123" target="_blank" rel="noopener noreferrer">
          <div className="ellipse-icon" style={{backgroundColor:'white!important'}}>
            <img src={require('./images/facebook.svg').default} alt="Facebook" />
          </div>
        </a>
      </div>

      <hr className="footer-line" />
      <div className="copyright">
        © TAN YEONG LIM 2024
      </div>
    </footer>


    </div>

  );
};

export default Homepage;
